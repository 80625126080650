<template>
    <standard-page :status="process_step.status === 'Created'?'InProgress': process_step.status" :title="processDefinitionStep.name" v-if="processDefinitionStep && process_step">
        <template v-if="currentRouteName === 'view-process-step'" v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
            <span v-if="registry">
        <router-link :to="{ name: 'processdefinitions'}">Registry: {{registry.name | truncate(5, '.....')}}</router-link>
      </span>
            <span v-if="processDefinition">
        <router-link :to="{ name: 'process-definition-steps'}">Process Definition: {{processDefinition.name | truncate(5, '.....')}}</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'process'}">Processes</router-link>
      </span>
            <span v-if="process">
        <router-link :to="{ name: 'process-steps'}">Process: {{process.name | truncate(5, '.....')}}</router-link>
      </span>
            <span v-if="processDefinitionStep">Step: {{processDefinitionStep.name | truncate(10, '.....')}}</span>
        </template>
        <template v-else v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
            <span v-if="process.status=='Completed'">
        <router-link :to="{ name: 'complete-process'}">Completed Processes</router-link>
      </span>
            <span v-else>
        <router-link :to="{ name: 'ongoing-process'}">Ongoing Processes</router-link>
      </span>
            <span v-if="process">
        <router-link :to="{ name: 'ongoing-process-steps'}">Process: {{process.name | truncate(10, '.....')}}</router-link>
      </span>
            <span v-if="processDefinitionStep">Step: {{processDefinitionStep.name | truncate(10, '.....')}}</span>
        </template>
        <template v-if="process_step.status !== 'Attested' && attestors.includes(currentUser.did)" v-slot:buttons>
        <span class="d-none d-xl-block">
          <button @click="toAddAttribute" class="btn btn-orange rounded font-weight-bold mg-r-5" title="Add Attribute">
            <i class="fas fa-plus-square mg-r-10" />Add Attributes
          </button>
          <button class="btn btn-success rounded font-weight-bold  mg-r-5" data-target="#attestconfirmationmodal" data-toggle="modal" title="Attest" v-if="table && table.data().length>0">
            <i class="fas fa-lock mg-r-10" />Attest
          </button>
          <button class="btn btn-success rounded font-weight-bold  mg-r-5" data-target="#warningmodal" data-toggle="modal" title="Attest" v-else>
            <i class="fas fa-lock mg-r-10" />Attest
          </button>
        </span>
            <span class="d-xl-none btn-icon-list float-right">
            <button @click="toAddAttribute" class="btn btn-icon btn-orange rounded" title="Add Attribute">
                <i class="typcn typcn-document-add" />
            </button>
            <button class="btn btn-icon btn-success rounded" data-target="#attestconfirmationmodal" data-toggle="modal" title="Attest" v-if="table && table.data().length>0">
                <i class="typcn typcn-lock-closed" />
            </button>
            <button class="btn btn-icon btn-success rounded" data-target="#warningmodal" data-toggle="modal" title="Attest" v-else>
                <i class="typcn typcn-lock-closed" />
            </button>
        </span>
        </template>
        <template v-slot:content>

            <div class="az-content-label mg-b-5">Step Description</div>
            <div class="mg-b-20" v-html="processDefinitionStep.description?processDefinitionStep.description:'NA'"></div>

            <div class="az-content-label mg-t-20 mg-b-5">Attributes</div>
            <div class="mg-b-20" v-html="$DEFINITIONS.provenance.processStepAttributes"></div>

            <div class="az-content-body az-content-body-profile">
                <nav class="nav az-nav-line">
                    <a :class="{active: activeTab === 0}" @click="navigate(0)" class="nav-link" data-toggle="tab" href="#attributes">Attributes</a>
                    <a :class="{active: activeTab === 1}" @click="navigate(1)" class="nav-link" data-toggle="tab" href="#attachments">Attachments</a>
                </nav>
                <div class="az-tab-content">
                    <div :class="{'d-none': activeTab !== 0}" id="attributes">
                        <data-table :api="api" :columns="columns" :server-side="true" @ready="onReady" list-key="attributes" table-id="attribute_table" total-key="total" class="mg-t-30">
                        </data-table>
                    </div><!-- az-profile-body -->
                    <div :class="{'d-none': activeTab !== 1}" id="attachments" class="mg-t-30">
                        <process-attachments :registryid="registryid" :definitionid="processdefinitionid" :processid="processid" :processstepid="processstepid"></process-attachments>
                    </div><!-- az-profile-body -->
                </div>
            </div>

<!--            <data-table :api="api" :columns="columns" :server-side="true" @ready="onReady" list-key="attributes" table-id="attribute_table" total-key="total">-->
<!--            </data-table>-->
            <confirm-modal :onclick="removeAttribute" button-text="Continue" message="Are you sure you want to delete this attribute?" modal-id="removeattestormodal" type="warning"></confirm-modal>
            <confirm-modal :onclick="attest" button-text="Continue" message="Please make sure that all attributes are correct and added as you can't edit after attestation." modal-id="attestconfirmationmodal" type="alert"></confirm-modal>
            <confirm-modal :onclick="attest" button-text="Proceed" message="No attributes present. Are you want to proceed without attributes?" modal-id="warningmodal" type="alert"></confirm-modal>
        </template>
    </standard-page>
</template>

<script>
    import EventBus from "@/event-bus";
    import { mapGetters } from "vuex";
    import _ from "lodash";
    import ProcessAttachments from "./ProcessAttachments.vue";

    export default {
        name: "ViewProcessStep",
        components: {ProcessAttachments},
        computed: {
            ...mapGetters(["currentUser"]),
            currentRouteName() {
                return this.$route.name;
            }
        },
        props: ['registryid', 'processdefinitionid', 'processid', 'processdefinitionstepid', 'processstepid', 'nextprocessdefinitionstepid'],
        data() {
            return {
                processDefinitionStep: null,
                process_step: null,
                process: null,
                api: `${this.$provenance.defaults.baseURL}/provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.processstepid}/attributes`,
                columns: [
                    { type: 'name' },
                    { type: 'fact' },
                    { type: 'created' },
                    {
                        type: 'action',
                        defaultContent: `<div class="offset-2 btn-group btn-icon-list">
                                ${this.$StandardButtons.editButton('Edit Attribute')}
                                ${this.$StandardButtons.deleteButton('#removeattestormodal', 'Delete Attribute')}
                           </div>`
                    }
                ],
                table: null,
                attribute_for_delete: null,
                attestors: [],
                registry: null,
                processDefinition: null,
                activeTab: 0,
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await this.getProcess();
                await this.getProcessDefinitionStep();
                await this.getProcessStep();
                if (this.currentRouteName === 'view-process-step') {
                    await this.getRegistry();
                    await this.getProcessDefinition();
                }
            },
            navigate(index) {
                this.activeTab = index;
            },
            async getRegistry() {
                try {
                    let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}`);
                    this.registry = data;
                } catch (e) {
                    this.$toastr.e("Error retrieving Registry", "Error");
                } finally {

                }
            },
            async getProcessDefinition() {
                try {
                    let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}`);
                    this.processDefinition = data;
                } catch (e) {
                    this.$toastr.e("Error retrieving Process Definition", "Error");
                } finally {

                }
            },
            async getProcessDefinitionStep() {
                try {
                    let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${this.processdefinitionstepid}`);
                    this.processDefinitionStep = data;
                    if (this.processDefinitionStep) {
                        this.attestors = _.map(this.processDefinitionStep.attestors, 'did');
                    }
                } catch (e) {
                    this.$toastr.e("Error retrieving Process Definition", "Error");
                } finally {

                }
            },
            async getProcessStep() {
                try {

                    let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.processstepid}`);

                    this.process_step = data;
                } catch (e) {
                    this.$toastr.e("Error retrieving Process Definition", "Error");
                } finally {

                }
            },
            async getProcess() {
                try {
                    let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}`);
                    this.process = data;
                } catch (e) {

                } finally {
                }
            },
            toAddAttribute() {
                this.$router.push({ name: 'add-process-step-attribute', params: { processdefinitionstepid: this.processdefinitionstepid } });
            },
            async attest() {
                try {
                    EventBus.$emit('openLoader');
                    await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.processstepid}/attest`, {
                        process_definition_step_index: Number(this.processDefinitionStep.order)
                    });
                    await this.getProcessStep();
                    this.onReady(this.table);
                    EventBus.$emit('updatePendingStepCount');
                    EventBus.$emit('updateCompleteCount');
                    this.$toastr.s("Process Step Successfully attested!", 'Success');
                } catch (e) {
                    console.error(e);
                } finally {
                    EventBus.$emit('closeLoader');
                }
            },
            onReady(table) {

                function yesNoRadio(data, name) {
                    return `<div class="d-inline">
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="${name}" value="true" ${data === true ? 'checked' : ''}>
                              <label class="form-check-label">
                                Yes
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="${name}" value="false" ${data === false ? 'checked' : ''}>
                              <label class="form-check-label">
                                No
                              </label>
                            </div>
                            </div>

                    `;
                }

                function datePicker(data, name) {
                    return `<input name="${name}" class="form-control rounded" id="expiry" type="date" value="${data}">`;
                }

                this.table = table;
                let self = this;

                $(async function () {


                    // AmazeUI Datetimepicker
                    $('#datetimepicker').datetimepicker({
                        format: 'yyyy-mm-dd hh:ii',
                        autoclose: true
                    });

                    $('#attribute_table tbody').on('click', '.delete', function () {
                        let _row = table.row($(this).parents('tr')).data();
                        self.setAttributeToDelete(_row.attribute, _row.name, _row.fact.value, _row.fact.type);
                        table.ajax.reload();
                    });

                    $('#attribute_table tbody').on('click', '.edit', async function () {
                        let tr = $(this).closest('tr');
                        let cells = tr.children().toArray();
                        let row = self.table.row(tr);
                        let property = row.data();
                        console.log(property);
                        table.columns(2).visible(false);
                        // table.columns(4).visible(false);
                        let nameTd = $(cells[0]);
                        let nameInput = $(`<input class="editor" value="${property.name}"></input>`);
                        nameTd.empty();
                        nameTd.append(nameInput);
                        let valueTd = $(cells[1]);
                        let valueColumnDiv = $(`<div class="edit-fact-container"></div>`);
                        let typeColumnSelect = $(`<select class="form-control fact-type" style="width:95px" placeholder="Type" value="${property.fact.type}">
                          <option ${property.fact.type === 'String' ? 'selected' : ''} value="String">Text</option>
                          <option ${['U8', 'U16', 'U32', 'U128'].includes(property.fact.type) ? 'selected' : ''} value="Number">Number</option>
                          <option ${property.fact.type === 'Bool' ? 'selected' : ''} value="Bool">Yes/No</option>
                          <option ${property.fact.type === 'Date' ? 'selected' : ''} value="Date">Date</option>
                          </select>`);

                        function getValueInput() {
                            let valueInput;
                            if (typeColumnSelect.val() == 'Bool') {
                                valueInput = $(yesNoRadio(property.fact.value, 'typeRadio'));
                            } else if (typeColumnSelect.val() == 'Date') {
                                valueInput = $(datePicker(property.fact.value, 'Date'));
                            } else {
                                valueInput = $(`<input class="editor" value="${property.fact.value}"></input>`);
                            }
                            return valueInput;
                        }

                        let valueInput = getValueInput();
                        typeColumnSelect.on('change', function () {
                            valueInput.remove();
                            valueInput = getValueInput();
                            valueColumnDiv.append(valueInput);
                        });
                        valueColumnDiv.append(typeColumnSelect);
                        valueColumnDiv.append(valueInput);
                        valueTd.empty();
                        valueTd.append($(valueColumnDiv));
                        let saveBtn = $(`<button class="table-editor-button table-editor-check"><i class="fas fa-check"></i></button>`);
                        let cancelBtn = $(`<button class="table-editor-button table-editor-cancel"><i class="fas fa-times"></i></button>`);
                        let actionTd = $(cells[3]);
                        if (actionTd.toArray().length == 0) {
                            actionTd = $(cells[2]);
                        }
                        let editButton = actionTd.find('.edit');
                        editButton.hide();
                        let deleteButton = actionTd.find('.delete');
                        deleteButton.hide();
                        actionTd.prepend(cancelBtn);
                        actionTd.prepend(saveBtn);
                        actionTd.addClass('text-nowrap');
                        saveBtn.on('click', async function (e) {
                            e.stopPropagation();
                            let value;
                            if (typeColumnSelect.val() == 'Bool') {
                                value = valueTd.find('input[name=typeRadio]:checked').val();
                            } else {
                                value = valueInput.val();
                            }
                            await self.updateAttribute(property.attribute, nameInput.val(), typeColumnSelect.val(), value);

                            nameTd.text(nameInput.val());
                            valueTd.text(value);
                            saveBtn.remove();
                            cancelBtn.remove();
                            editButton.show();
                            deleteButton.show();
                            if ($('#attribute_table tbody').find('.table-editor-button').toArray().length == 0) {
                                table.columns(2).visible(true);
                                table.columns(3).visible(true);
                            }
                        });
                        cancelBtn.on('click', function (e) {
                            e.stopPropagation();
                            saveBtn.remove();
                            cancelBtn.remove();
                            nameTd.text(property.name);
                            valueTd.text(property.fact.value);
                            saveBtn.remove();
                            cancelBtn.remove();
                            editButton.show();
                            deleteButton.show();
                            if ($('#attribute_table tbody').find('.table-editor-button').toArray().length == 0) {
                                table.columns(2).visible(true);
                                table.columns(3).visible(true);
                            }
                        });

                    });

                });
                if (this.process_step.status === 'Attested' || !this.attestors.includes(this.currentUser.did)) {
                    this.table.column(3).visible(false);
                }
            },
            async updateAttribute(attribute, name, type, value) {
                try {
                    await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.processstepid}`, {
                        update_attributes: [{
                            "attribute": Number(attribute),
                            "name": name,
                            "type": this.getDatatypeForUpdate(type, value),
                            "value": value
                        }]
                    });
                } catch (e) {
                    this.$toastr.e("Attribute updating failed", 'Error');
                } finally {
                    EventBus.$emit('closeLoader');
                }
            },
            getDatatype(type) {
                switch (type) {
                    case "Text":
                        return 'String';
                    case "Yes/No":
                        return "Bool";
                    default:
                        return type;

                }
            },
            getDatatypeForUpdate(type, val) {
                switch (type) {
                    case "Text":
                        return 'String';
                    case "Number":
                        if (val >= 0 && val < 256) {
                            return "U8";
                        } else if (val > 255 && val < 65537) {
                            return "U16";
                        } else if (val > 65536 && val < 4000001) {
                            return "U32";
                        } else if (val) {
                            return "U128";
                        }
                    case "Yes/No":
                        return "Bool";
                    case "Date":
                        return "Date";
                }
            },
            setAttributeToDelete(attribute, name, value, type) {
                this.attribute_for_delete = {
                    attribute: attribute,
                    name: name,
                    value: value,
                    type: this.getDatatype(type)
                };
            },
            async removeAttribute() {
                try {
                    EventBus.$emit('openLoader');
                    await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.processstepid}`, {
                        remove_attributes: [this.attribute_for_delete]
                    });
                    this.$toastr.s("Attribute Successfully deleted!", 'Success');
                    this.attribute_for_delete = null;
                } catch (e) {
                    this.$toastr.e("Attribute deletion failed", 'Error');
                } finally {
                    this.table.ajax.reload();
                    EventBus.$emit('closeLoader');
                }
            }
        }
    }
</script>

<style scoped>
</style>
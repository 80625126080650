<template>
    <!--    <div>-->
    <data-table :api="api" :columns="columns" :server-side="false" list-key="process_attachment_list" table-id="attachment_table"  @ready="onReady"></data-table>
    <!--    </div>-->
</template>

<script>
export default {
    name: "DidAttachments",
    props: ['registryid', 'definitionid', 'processid', 'processstepid'],
    data() {
        return {
            catalogName: this.catalogNameProp,
            short_name: this.short_nameProp,
            api: `${this.$provenance.defaults.baseURL}/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/process_steps/${this.processstepid}/documents`,
            columns: [
                {title: 'Name', data: 'label'},
                {title: 'File Name', data: 'name'},
                {title: 'File Hash', data: 'hash', render: data => this.$options.filters.hex_format(data, 15, '...')},
                {type: 'created'},
                {
                    type: 'action',
                    defaultContent: `
                          <div class="btn-group btn-icon-list">
                                <span class="text-decoration-none text-info download mg-l-10" style="cursor: pointer">
                                    <i class="fas fa-download fa-lg" title="Download attachment"></i>
                                </span>
                          </div>`
                },
            ],
            table: null,
        }
    },
    methods: {
        onReady(table) {
            this.table = table;
            let self = this;

            $('#attachment_table tbody').on('click', '.download', function () {
                let entity = table.row($(this).parents('tr')).data();
                self.downloadAttachment(entity);
                table.ajax.reload();
            });
        },
        async downloadAttachment(attachment) {
            try {
                let {data} = await this.$provenance.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/process_steps/${this.processstepid}/documents/${attachment.id}`);
                let link = document.createElement('a');
                link.href = 'data:application/octet-stream;base64,' + data;
                link.download = attachment.name;
                link.click();
            }catch (e) {
                console.log(e)
            }
        },
    }
}
</script>